.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  
  .not-found-text {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .not-found-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  

  
  
