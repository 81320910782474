
  
  .lateral-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 230px;
    height: 100vh;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    background-color: var(--black);
    color: var(--white);
    z-index: 1000;
    
  }
  
  .navbar {
    transition: all 0.2s ease-in-out;
  }
  
  .lateral-nav.open {
    transform: translateX(0%);
  }
  
  .toggle-button-wrapper {
    position: fixed;
    top: 20px;
    left: 15px;
    z-index: 200000;
  }
  
  .toggle-button {
    font-size: 1.3rem;
    cursor: pointer;
    color: var(--white);
    z-index: 10001;
  }

  .open-icon{
    color: var(--black);
    z-index: 10001;
  }
  
  .nav-links {
    margin-top: 90px;
    list-style: none;
  }
  
  .navbar ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .nav-links li {
    padding: 5px 0px 5px 18px;
    text-align: left;
    transition: all .3s;
    border-bottom: 1px solid var(--white);
  }
  .nav-links li:hover {
    background-color: var(--white);
    color: var(--black);
  }
  
  .nav-links a {
    text-decoration: none;
    font-size: 1rem;
  }
  
  /* add styles for the active link here */
  

  .icons-nav{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: var(--white);
    transition: all .3s;
    cursor: pointer;
   
  }

  .nav-links li:hover .icons-nav {
    color: var(--black);
  }

  .sub-link{
    padding-left: 27px;
    display: none;
    transition: all .3s;
  }
  .sub-link:hover .icon-animation{
    animation: shake .8s infinite;
  }
  
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .sub-link .icons-nav{
    border-top: 1px solid var(--black);;

  }

  .no-hover{
    background-color: var(--black);
    color: var(--white);
  }

  .open-sub-link{
    display: block;
  }

  .nav-role{
    font-size: 1.7rem;
    font-weight: 800 !important;
    text-transform: uppercase;
    position: absolute;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  /* flip icon animation */
  .nav-links li:hover .flip-icon {
    animation-name: rotate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

  /* overlay */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    backdrop-filter: blur(2px);
    display: none; /* hide the overlay by default */
  }
  
  .lateral-nav.open ~ .overlay {
    display: block; /* only show the overlay when the nav is open */
  }
  