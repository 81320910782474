@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --black: #29292b;
  --white: #ffffff;
  --red: 229, 57, 53;
  --orange: 251, 140, 0;
  --yellow: 253, 216, 53;
  --green: 67, 160, 71;
  --blue: 30, 136, 229;
  --indigo: 57, 73, 171;
  --black2: 40, 41, 44;
  --white2: 255, 255, 255;

  --theme-color: var(--black2);
}

body {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Nunito', sans-serif;
  height: 100vh;
  /* margin-bottom: 50px; */
  background-color: var(--white);
  color: var(--black);
}

a {
  text-decoration: none;
  color: var(--black);
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1{
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

h1,
h2 {
  font-weight: 600;
  color: var(--black);
  text-transform: uppercase;
}

h3 {
  font-weight: 500;
  color: var(--black);
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 100px 50px 100px;
  position: relative;
  
}
h2{
  text-align: left;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 2px solid rgba(var(--theme-color));
  margin-bottom: 50px;
  position: relative;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color:  rgba(var(--theme-color));
}

.left-nav{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:40px;
}

/* checkbox day-night */
/* .day-night-container{
  position: absolute;
  left: -20px;
} */
.toggle-switch {
  position: relative;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.label-switch {
  position: absolute;
  width: 60px;
  height: 30px;
  background-color: var(--black);
  border-radius: 50px;
  cursor: pointer;
}

.input-switch {
  position: absolute;
  display: none;
}

.slider {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  transition: 0.3s;
  border: .7px solid var(--black);
}

.hover{
  transition: all .2s;
  cursor: pointer;
}

.hover:hover{
  transform: scale(1.14);
}

.input-switch:checked ~ .slider {
  background-color: var(--white);

}

.slider::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -27px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: inset 28px -4px 0px 0px var(--white);
  background-color: var(--black);
  transition: 0.3s;
}

.input-switch:checked ~ .slider::before {
  transform: translateX(25px);
  background-color: var(--black);
  width: 25px;
  height: 25px;
  box-shadow: none;
}


/*  */
.home-container{
  width: 100%;
  height: 40vh;
}
.hero-content{
  font-size: 2.1rem !important;
}
.hero-content p{
  font-size: 1.5rem !important;
  text-align: center;
}

/* theme picker */
.btn-picker{
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-picker-section{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  border-radius: 5px;
  border: 1px solid var(--black);
  padding: 5px;
}

.absolute-home{
  position: absolute;
  left: 0;
  top:80px;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.home-buttons{
  display: flex;
  gap: 50px;
  margin: 200px auto 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 210px;
  width: 400px;
  z-index: 3;
  position: relative;
  
}

.home-buttons .btn2{
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  border: 1px solid var(--black);
  box-shadow: 1.2px 1.5px 0px var(--black);
}



.theme-picker-section > input {
  height: 1.4rem;
  width: 1.4rem;
  margin: 0 5px;
  outline: none;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--white);
}

.theme-picker-section > input:before,
.theme-picker-section > input:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  z-index: 2;
}

.theme-picker-section > input:after {
  height: 100%;
  width: 100%;
  border: 1px solid var(--white);
}

.theme-picker-section > input:before {
  display: none;
  height: 110%;
  width: 110%;
  border: 0.1rem solid white;
}

.theme-picker-section > input:not(:checked):hover:before,
.theme-picker-section > input:not(:checked):active:before {
  display: block;
  border-color: rgba(255, 255, 255, 0.5);
}

.theme-picker-section > input:checked:before {
  display: block;
}

.theme-picker-section > input[value="green"]:after {
  background-color: rgb(var(--green));
}

.theme-picker-section > input[value="red"]:after {
  background-color: rgb(var(--red));
}

.theme-picker-section > input[value="orange"]:after {
  background-color: rgb(var(--orange));
}

.theme-picker-section > input[value="yellow"]:after {
  background-color: rgb(var(--yellow));
}

#theme-picker-section > input[value="green"]:after {
  background-color: rgb(var(--green));
}

.theme-picker-section > input[value="blue"]:after {
  background-color: rgb(var(--blue));
}

.theme-picker-section > input[value="indigo"]:after {
  background-color: rgb(var(--indigo));
}

.theme-picker-section > input[value="violet"]:after {
  background-color: rgb(var(--violet));
}
.theme-picker-section > input[value="black2"]:after {
  background-color: rgb(var(--black2));
}

.boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.boxes div {
  padding: 30px;
  border: 1px solid rgba(var(--theme-color));
  border-radius: 10px;
}

.boxes h2 {
  margin-top: 20px;
}

.boxes a:hover {
  color:  rgba(var(--theme-color));
}

.form {
  width: 70%;
  margin: 0 auto;
}
.center-element{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.input-form{
  width: 200px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}
.input-form-center{
  width: 300px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.textarea{
  min-width: 400px !important;
  min-height: 150px !important;
}
.textarea2{
  margin-top: 20px;
  min-width: 100% !important;
  min-height: 130px;
}

select{
  width: 200px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

input[type=file]{
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 30px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}
.underline{
  text-decoration: underline;
  text-underline-offset: 3px;
}
.btn-languages{
  /* padding: 3px 12px; */
  border: none;
  border-radius: 5px;
  color: var(--black);
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: all .3s;
  position: relative;
}

.btn-actions{
  padding: 3px 12px;
  border: none;
  border-radius: 5px;
  color: var(--white);
  background-color: transparent;
  font-size: 1rem !important;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: all .3s;
  position: relative;
}
.btn-actions-reverse{
  padding: 3px 12px;
  border-radius: 5px;
  color: var(--black);
  font-size: 1rem !important;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: all .3s;
  position: relative;
}
.btn-actions:hover{
  transform: scale(.95);
}
.btn-actions::after{
  content: '';
  position: absolute;
  top: 0px;
  left: -5px;
  width: 1px;
  height: 100%;
  background-color: var(--white);
}

.btn {
  padding: 7px 12px;
  border: 1px solid var(--black);
  border-radius: 5px;
  background: var(--black);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: 1.2px 1.5px 0px var(--black);

}

.btn2 {
  padding: 7px 12px;
  border: 1px solid var(--black);
  border-radius: 5px;
  background: var(--black);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

}
.btn-translations {
border: none;
  background: transparent;
  color: var(--black);
  cursor: pointer;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .4s;
}
.btn-translations:hover {
  transform: scale(1.1);
}

Link {
  max-width: 100px;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: var(--white);
  color: var(--black);
  min-width: 100px;
  text-transform: uppercase;
}


.btn-block {
  min-width: 100px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.margin-top{
  margin-top: 50px;
  margin-bottom: 20px;
}

.btn-skip{
  position: absolute;
  right: 100px;
}



.btn-back {
  width: 150px;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}
.btn2:hover {
  transform: scale(0.98);
}


.ticket-created {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 50px;
}

.ticket-number {
  margin-bottom: 30px;
}

.ticket-number h2 {
  font-size: 2.3rem;
  margin-bottom: 10px;
}

.ticket-number p {
  font-size: 1.3rem;
}

.ticket-info {
  font-size: 1.3rem;
}

.ticket,
.ticket-headings {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #f4f4f4;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
}

.ticket-headings {
  font-weight: 700;
}

.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  background-color: var(--white);
  border: 2px solid var(--black);
  color: var(--black);
  border-radius: 5px;
  height: 40px;
  padding: 10px;
  width: 300px;
  text-align: center;
  margin: 30px auto;
}

.search-box::placeholder {
  color: var(--gray);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.icons{
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-icon{
  transform: rotate(-90deg);
}

/* FLEX CENTER */
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
/* FLEX CENTER */

/* TRANSLATIONS */

.single-translation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
  text-transform: uppercase;
  border: 1px solid var(--black);
  padding: 0px 15px;
  box-shadow: 1px 1px 0px var(--black);
  transition: all .3s;
  background-color: var(--black);
  color: var(--white);
}



.translations-buttons-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
/* TRANSLATIONS */

/* VIDEO TABLE */
.rdt_TableHeadRow{
  background-color: var(--white) !important;
  color: var(--black) !important;
}


.rdt_TableRow{
  background-color: var(--white) !important;
  color: var(--black) !important;
}
/* .jWpMhA{
  border-bottom: 1px solid var(--black);
}
.cKBiPJ:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.32) !important;
} */

.rdt_Pagination{
  background-color: var(--white) !important;
  color: var(--black) !important;
}

.jriwbs svg{
  fill: var(--black) !important;
}
/* VIDEO TABLE */

/* EDITOR */
.editor-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin-top: 0px;
}

.editor-resume{
  margin-bottom: 200px;
}

.editor-block{
  width: 100%;
  height: 400px;
}

.editor-header{
  margin: 20px 0;
  display: flex;
  font-size: 1.2rem;
  justify-content: space-between;
  width: 100%;
}

.highlighted_ace {
  background-color: #8148F5;
}

/* EDITOR */

/* CREATE VIDEO */

.create-buttons-container{
  margin: 85px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.chose-transcriber-container{
  margin: 65px 0 30px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.create-buttons-container h2{
  font-size: 2rem;
}
.prompt-container{
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.prompt-container .textarea{
  
}

/* SCRIPT EDITOS */
.script-editor-buttons-container{
  display: flex;
  gap: 50px;
}

.ace_editor{
  border: .3px solid var(--black) !important;
}

.btn-trash{
  background-color: white;
  color: rgb(250, 67, 67);
  border-color: rgb(250, 67, 67);
  box-shadow: 1.2px 1.5px 0px rgb(250, 67, 67);
}
.btn-download{
  background-color: white;
  color: rgb(79, 204, 133);
  border-color: rgb(79, 204, 133);
  box-shadow: 1.2px 1.5px 0px rgb(79, 204, 133);
}
.btn-trash{
  background-color: white;
  color: rgb(250, 67, 67);
  border-color: rgb(250, 67, 67);
  box-shadow: 1.2px 1.5px 0px rgb(250, 67, 67);
}

.title-icon{
  transform: rotate(90deg);
}

.upload-file-container h1:nth-child(1){
  margin-top: 50px;
  text-align: start !important;
  font-size: 2rem !important;
}
.upload-titles{
  margin-top: 50px;
}

.uploader-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto;
  gap: 0px;
}

.file-uploader-actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--black);
}

/* CREATE VIDEO */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .boxes {
    grid-template-columns: 1fr;
  }

  .form {
    width: 90%;
  }

  .ticket-created h2,
  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}


/* datatble arrows */

.cofJRH{
  color: var(--black) !important;
  fill: var(--black)!important;
  background-color: rgb(232, 232, 232);
}


/* settings */

.settings-container{
  margin: 50px auto;
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 250px;
  border: .6px solid var(--black);
  padding: 20px;
  border-radius: 5px;
  box-shadow: .7px .8px 0px var(--black);
  transition: all .3s;
}

.settings-container:hover{
  border: 1px solid var(--black);
  box-shadow: 1px 1.1px 0px var(--black);
}

.available-language-container{
  display: flex;

  align-items: center;
  gap: 10px;
  margin: 20px 0;
}


/* settings profile */

.forms-settings{
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-top: 50px;
}

.forms-settings > div{
  width: 100%;
  border: .6px solid var(--black);
  padding: 20px;
  border-radius: 5px;
  box-shadow: .7px .8px 0px var(--black);
  transition: all .3s;
}
.forms-settings > div:hover{
  border: 1px solid var(--black);
  box-shadow: 1px 1.1px 0px var(--black);
}

.forms-settings div h2{
  font-size: 1.2rem;
  margin-bottom: 20px;
}



/* resume form */

.resume-form{
  background-color: var(--white);
  position: fixed;
  top: 350px;
  left: 50%;
  width: 500px;
  transform: translate(-50%, -50%);
  padding: 50px;
  border-radius: 10px;
  border: 1px solid var(--black);
  z-index: 9999;
}

.resume-all-form{
  background-color: var(--white);
  position: fixed;
  top: 350px;
  left: 50%;
  width: 500px;
  transform: translate(-50%, -50%);
  padding: 50px;
  border-radius: 10px;
  border: 1px solid var(--black);
  z-index: 9999999;
}
.resume-all-form-content{
  background-color: var(--white);
  position: fixed;
  top: 350px;
  left: 50%;
  width: 700px;
  transform: translate(-50%, -50%);
  padding: 50px;
  border-radius: 10px;
  border: 1px solid var(--black);
  z-index: 9999999;
}


.close-form{
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: var(--black);
}

.landing-content-container{
  width: 90%;
  margin: 0 auto;
}

.landing-content-header{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.content-landing-header-right{
  /* justify-self: flex-end; */
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;

}


.titles-container, .bulletpoints-container, .global-summary-container {
  background-color: var(--white);
  color: var(--black);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 60px;
  border: 1px solid var(--black);
  box-shadow: 1.2px 1.5px 0px var(--black);
  cursor: pointer;
  transition: all .5s;
}

.container-hover:hover{
  transform: scale(.98);
}
.bulletpoints-container{
  display: flex;
  gap: 40px;
  border: none;
  box-shadow: none;
  padding: 0;
}

.h3-landing-content{
  color: var(--black);
  font-size: 2rem !important;
  text-transform: uppercase;
  margin-bottom: 18px;
  text-decoration: underline;
  text-decoration-color: var(--black);
  text-underline-offset: 5px;
  
  
}
.bulletpoints-container h3{
  color: var(--black);
  font-size: 2rem !important;
  text-transform: uppercase;
  
}

.bulletpoint{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  /* height: 100%; */
  flex: 1; 
  border: 1px solid var(--black);
  box-shadow: 1.2px 1.5px 0px var(--black);
  padding: 20px;
  border-radius: 10px;
  transition: all .4s;
  
}

.editable-input{
  border: none;
  color: var(--black);
  width: 100%;
  font-size: 1rem;
}

.bulletpoint-title{
  

}
.blocked-link{
  background-color: rgb(255, 200, 200);
}
.blocked-link:hover{
  transform: scale(1);
  cursor: default;
}


