@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.container-bio{
    font-family: "DM Sans", sans-serif;
    background-color: black !important;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 15px;
    overflow: hidden;
}

body:has(.container-bio){
    background-color: black;
  }

.bio-header{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    height: 50px;
}

.bio-header-buttons{
    width: 100%;
}

.bio-title{
    font-size: 1.7rem;
}
.bio-titles h1{
    margin-bottom: 12px !important;
}

.bio-logo{
    width: 110px;
}

.bio-subtitle{
    text-align: center !important;
    margin-top: 40px;
    font-size: 1.3rem !important;
    color: white !important;
    text-transform: none;
    font-weight: normal !important;
    letter-spacing: 1px;
}
.bio-paragraph{
    text-align: center !important;
    font-size: 1rem !important;
    color: white !important;
    text-transform: none;
    font-weight: normal !important;
    letter-spacing: 1px;
}


.links-bio{
    width: 100%;
    margin-top: 40px;
}

.link-bio{
    height: 55px;
    margin: 20px 0;
    border-radius: 50px;
    text-align: center !important;
    color: black !important;
    background: white !important;
    letter-spacing: 1px;
    line-height: none;
    border: 1.5px solid white;
    transition: all .3s;
    cursor: pointer;
    
}
.link-actions{
    position: relative;
}

.delete-icon{
    cursor: pointer;
    transition: all .3s;
    position: absolute;
    color: black;
    right: 20px;
    top: 10px;
    z-index: 100;
}

.delete-icon:hover{
    transform: scale(1.2);
}
.edit-icon{
    cursor: pointer;
    transition: all .3s;
    position: absolute;
    color: black;
    right: 70px;
    top: 10px;
    z-index: 100;
}

.edit-icon:hover{
    transform: scale(1.2);
}

.edit-link-container{
    display: flex;
    gap: 30px;
}

.link-bio a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100%;
   
}
.link-bio a p{
    font-size: .9rem;
    max-width: 65%;
    letter-spacing: .6px;
}





.image-bio-link{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 1px;
}

.social-media{
    margin-top: 15px;
    gap: 70px;
}

.bio-icons{
    color: white !important;
}

.btn-links{
    margin: 30px 0;
}