.downloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  /* margin-top: 100px; */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffffb8;
  z-index: 10;
}


.downloader-icon {
    animation: rotate 4.5s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

.downloader-text {
  margin-top: 20px;
  opacity: 1;
  font-size: 1.5rem;
}
  
  @keyframes text-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  