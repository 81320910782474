.single-video-container{
    margin: 0 auto;
    position: relative;
    margin-bottom: 100px;

}

.single-video-title{
    display: flex;
    justify-content: center;
}


.single-video-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    /* text-transform: uppercase; */
    margin-bottom: 10px;
}
.single-video-header-left{
    display: flex;
    /* text-transform: uppercase; */
    flex-direction: column;
}

.single-video-header-right{
    display: flex;
    flex-direction: column;
}

.single-video-header-center{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.single-video-header-center-label{
    text-align: center;
}

.single-video-header-center-input{
    width: 50%;
}
.single-video-header-center button{
    margin: 20px 0 50px 0 !important;
}

.masterclass-title{
    font-size: 3rem;
    cursor: pointer;
}

.change-title{
    display: flex;
    gap: 30px;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-title{
    font-size: 1rem;
}

.modified:hover::before {
    content: "Last modification";
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--black);
    color: var(--white);
    padding: 5px;
    border-radius: 5px;
    font-size: 1rem;
  }
  


.translation-file-container{
    margin-bottom: 40px;
} 

.actions-title{
    text-transform: uppercase;
    font-size: 1rem;
}
.original-buttons-container{
    display: flex;
    gap: 50px;
    margin-top: 15px;
}

.dacast-detection{
    cursor: pointer;
    text-decoration: underline;
}
.dacast-detection:hover{
   color:gray;
}
.dacast-wrapp{
    background: rgb(227, 226, 226);
    margin-top:20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
   
}
.dacast-playlits-wrap{
    height: 300px;
    overflow: auto;
}
.chapters-dacast-wrapper{
    width: 90%;
    margin: 0 auto;
    top: 0;
    z-index: 10;
    margin-bottom: 20px;
    position: sticky;
    background: #ffffffad;
}

.dacast-wrapp h4{
    text-transform: none;
    margin-bottom: 10px;
}

.dacast-list{
    text-transform: none;
    columns: 3;
    background: rgb(227, 226, 226);
}
.dacast-list li{
    margin-bottom: 10px;
    break-inside: avoid-column;
}

.dacast-list.show{
    display: inline;
}

.dacast-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    background-color: var(--white);
    border: 2px solid var(--black);
    color: var(--black);
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    width: 50%;
    text-align: left;
  
}
.create-sync-dacast{
    margin-left: 5px !important;
    font-size: 0.8em !important;
}
.sync-dacast-wrapp{
    margin-bottom: 20px;
    display: flex;
}

.create-translation{
    margin-top: 20px;
    width: auto;
}

.btn-danger{
    background: #cf5b5b !important;
    font-weight: 400 !important; 
}
.translation-file-buttons-ext{
    display: flex;
    gap: 10px;
}

.btn-delete{
    position: absolute;
    right: 20px;
    bottom:30px;
    transition: all .3s;
    cursor: pointer;
}

.btn-delete:hover{
    transform: scale(1.09);
  }

  .btn-absolute{
    position: absolute;
    right: 0px;
    top: 20px;
  }

  .btn-title{
    max-height: 37px !important;
  }



  /* chapters */
  .chapter-container{
    margin: 20px auto;
    padding: 20px;
    width: 90%;
    border-radius: 15px;
    position: relative;
    text-align: left !important;
    border: 1px solid var(--black);
    border-radius: 10px;
    box-shadow: 1px 1.2px 0px var(--black);
  }

  .uploader-container-center{
  
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .audio-player-container{
    margin: 30px 0px 40px 0px;
  }
  .audio-player-container audio{
    width: 600px !important;
    height: 54px;
}


